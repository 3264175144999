<template>
  <div>
    <div id="linesDiv">
      <p v-for="(line, index) in poemLines" :key="index">
        {{ line }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PoemDisplay",
  props: {
    currentIdx: {type: Number, default: 41}
  },
  computed: {
    poemLines: function () {
      return this.$store.getters.getLines(this.currentIdx);
    },
  },
};
</script>
