<template>
  <div class="poem">
    <grid :currentIdx="currentIdx" />
    <poem-display :currentIdx="currentIdx" />
  </div>
</template>

<script>
import Grid from "@/components/Grid.vue"; // @ is an alias to /src
import PoemDisplay from "@/components/PoemDisplay.vue"; // @ is an alias to /src

export default {
  name: "Poem",
  components: {
    Grid,
    PoemDisplay,
  },
  props: {
    currentIdx: Number,
  },
  mounted() {
    this.$store.dispatch("getPosts");
  }
};
</script>
