<template>
  <div class="about">
    <p>Welcome to <i>HELIX</i>, an experimental antilinear meditation on the mundane / poem-as-map. This project is an extension of my work with Stanford NLP's <a href="https://nlp.stanford.edu/projects/glove/">GloVe</a> code, which began with my Homolinguistic Translation project. You can check <a href="https://nataliejaneedson.com/poetry-tool/homolinguistic/">that project</a> out in the <a href="https://nataliejaneedson.com/poetry-tool/">poetry tools</a> section of my website.</p>

    <p> To put it briefly, <i>HELIX</i> is a poem about being attentive to your surroundings in the everyday and fully experiencing feelings as soon as they arise. The helix is the rim of the outer ear, the shape of our DNA, a spiral shape that encloses us in the circumstance of our lives. By allowing the GloVe software to interfere and interpret lines in select portions of the map, I hope to evoke the parts of our daily existence that we have no control over and get at the surreality of human existence. What is the chain of events that has led to me existing right here, at this time, in this body?</p>

   <p>In this work, I've asked GloVe to "translate" certain parts of the source text, which is comprised of random permutations of 243 lines I wrote in early 2022. This "translation" occurs with varying degrees of frequency across the surface of the map. There are portions of the map that GloVe has not interfered with, and there are parts that GloVe has edited heavily. As you traverse the map, the poem's lines will move in and out of sense. If you venture past the edge of the map, you'll be placed at a random point on the far edge—again, the reader is not in control here, and neither is the writer. The poem has millions of possible forms, and regenerates itself every morning when the sun rises at my home in Portland, Oregon.</p>

   <p>I'm very open to discussion about the work. Feel free to <a href="mailto:nataliejaneedson@gmail.com">email me</a> or check out my other work and publications at <a href="https://nataliejaneedson.com/">nataliejaneedson.com.</a></p>

   <br><br>

   <h2><i><router-link to="/poem/41">Read the Poem</router-link></i></h2>
  </div>
</template>

<style scoped>
	a {
		color: var(--link-color);
	}
</style>