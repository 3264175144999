<template>
  <div>
    <div class="icon" id="up" @click="up()">
      <font-awesome-icon :icon="['fas', 'chevron-up']" size="2x" />
    </div>
    <div class="icon" id="left" @click="left()">
      <font-awesome-icon :icon="['fas', 'chevron-left']" size="2x" />
    </div>
    <div class="icon" id="right" @click="right()">
      <font-awesome-icon :icon="['fas', 'chevron-right']" size="2x" />
    </div>
    <div class="icon" id="down" @click="down()">
      <font-awesome-icon :icon="['fas', 'chevron-down']" size="2x" />
    </div>
  </div>
  <div id="container"></div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  currentIdx: Number,
});

// NAV STUFF

import router from "@/router";

function randomElement(elems) {
  return elems[Math.floor(Math.random() * elems.length)];
}

function left() {
  var nextIdx;
  if (props.currentIdx % 9 == 1) {
    nextIdx = randomElement([9, 18, 27, 36, 45, 54, 63, 72, 81]);
  } else {
    nextIdx = parseInt(props.currentIdx) - 1;
  }
  router.push({ path: `/poem/${nextIdx}` });
}

function right() {
  var nextIdx;
  if (props.currentIdx % 9 == 0) {
    nextIdx = randomElement([1, 10, 19, 28, 37, 46, 55, 64, 73]);
  } else {
    nextIdx = parseInt(props.currentIdx) + 1;
  }
  router.push({ path: `/poem/${nextIdx}` });
}

function up() {
  var nextIdx;
  if (props.currentIdx >= 1 && props.currentIdx <= 9) {
    nextIdx = randomElement([73, 74, 75, 76, 77, 78, 79, 80, 81]);
  } else {
    nextIdx = parseInt(props.currentIdx) - 9;
  }
  router.push({ path: `/poem/${nextIdx}` });
}

function down() {
  var nextIdx;
  if (props.currentIdx >= 73 && props.currentIdx <= 81) {
    nextIdx = randomElement([1, 2, 3, 4, 5, 6, 7, 8, 9]);
  } else {
    nextIdx = parseInt(props.currentIdx) + 9;
  }
  router.push({ path: `/poem/${nextIdx}` });
}
</script>
<script>
function generateGrid(currentIdx) {
  const container = document.getElementById("container");

  var inactiveSquareColor = "#bdccb8";
  var activeSquareColor = "#a3b69a";

  container.style.setProperty("--grid-rows", 9);
  container.style.setProperty("--grid-cols", 9);

  // remove all previous cells
  while (container.firstChild) {
    container.removeChild(container.firstChild);
  }

  for (var c = 1; c <= 81; c++) {
    let cell = document.createElement("div");
    cell.style.setProperty("height", "30px");
    cell.style.setProperty("width", "30px");
    if (c == currentIdx) {
      cell.style.setProperty("background-color", activeSquareColor);
    } else {
      cell.style.setProperty("background-color", inactiveSquareColor);
    }

    container.appendChild(cell).className = "grid-square";
  }
}
export default {
  name: "Grid",
  props: {
    currentIdx: Number,
  },
  mounted() {
    generateGrid(this.currentIdx);
  },
  updated() {
    generateGrid(this.currentIdx);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#container {
  display: grid;
  grid-gap: 1px;
  grid-template-rows: repeat(9, 1fr);
  grid-template-columns: repeat(9, 1fr);
  width: 279px;
  margin: auto;
  padding: 30px 0;
  --grid-rows: 9;
  --grid-columns: 9;
}

.grid-square {
  height: 30px;
  width: 30px;
  display: inline-block;
}

.icon {
  padding: 10px;
  display: inline-block;
}
</style>
